<template>
  <div class="app-container">
    <div class="top" style="margin: 10px 0">
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-plus"
       
        @click="showAdd"
        >新增</el-button
      >
    </div>
    <el-table
      :data="listData"
      style="width: 100%; margin-bottom: 20px; text-align: center"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="id" label="ID" align="center"></el-table-column>
      <el-table-column
        prop="name"
        label="标识"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="description"
        label="名称"
        align="center"
      ></el-table-column>

      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-link
            type="primary"
            class="m-r-20"
            icon="el-icon-edit"
            :underline="false"
            @click="showEdit(scope.row)"
            >编辑</el-link
          >
          <el-link
            type="danger"
            icon="el-icon-delete"
            :underline="false"
            @click="handleDel(scope.row.id)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="handelType === 'create' ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      @opened="getTreeData"
      width="800px"
      center
    >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="100px"
        :inline="true"
        class="inlineForm"
        v-loading="formLoading"
      >
        <el-form-item label="标识">
          <el-input
            v-model="form.name"
            class="inlineFormInput"
            :disabled="handelType == 'create' ? false : true"
            size="small"
          />
        </el-form-item>
        <el-form-item label="名称">
          <el-input
            v-model="form.description"
            class="inlineFormInput"
            size="small"
            :disabled="handelType == 'create' ? false : true"
          />
        </el-form-item>

        <el-form-item label="权限">
          <el-tree
            ref="tree"
            :data="typeList"
            :default-checked-keys="defaultChecked"
            :props="defaultProps"
            default-expand-all
            show-checkbox
            check-strictly
            node-key="id"
            @check="checkHandle"
          >
            <div class="actionsWrap" slot-scope="{ node, data }">
              <div class="actionFather">{{ node.label }}</div>
              <div v-if="data.actions != ''" class="actionList">
                <el-checkbox-group
                  v-model="actionList[data.id]"
                  @change="handleActionChange($event, data.id)"
                >
                  <el-checkbox
                    :label="item"
                    v-for="(item, index) in getActions(data.actions)"
                    :key="index"
                  ></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </el-tree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>

        <el-button type="primary" @click="saveData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/common";
import apiPermission from "@/api/permissionVue";

export default {
  data() {
    return {
      listData: [],
      handelType: "create",
      dialogVisible: false,
      form: {
        description: "",
        id: 0,
        name: "",
        permissionList: [],
      },
      curId: "",
      formLoading: false,
      actionList: {},
      rulesStr: "",
      typeList: [],
      defaultChecked: [],
      defaultProps: {
        children: "child",
        label: "title",
      },
    };
  },

  created() {
    this.getDataList();
    this.getRulesList();
  },
  methods: {
    async getDataList() {
      await api.commonGet({ apiName: "/role" }).then((res) => {
        this.listData = res.data.data;
      });
    },

    handleActionChange(e, id) {
      console.log(e, id);
    },
    checkHandle() {
      console.log();
      const halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys().join(",");
      const checkedKeys = this.$refs.tree.getCheckedKeys().join(",");
      console.log(halfCheckedKeys, checkedKeys);
      if (halfCheckedKeys.length && checkedKeys.length) {
        this.rulesStr = halfCheckedKeys + "," + checkedKeys;
      } else if (halfCheckedKeys.length && checkedKeys.length === 0) {
        this.rulesStr = halfCheckedKeys;
      } else if (halfCheckedKeys.length === 0 && checkedKeys.length) {
        this.rulesStr = checkedKeys;
      } else {
        this.rulesStr = "";
      }

      let rulesArr = this.rulesStr.split(",");

      this.form.permissionList = [];
      rulesArr.forEach((element) => {
        this.form.permissionList.push({ id: element, actions: "" });
      });
    },
    getRulesList() {
      apiPermission.getTreeList().then((res) => {
        this.typeList = res.data;

        //将所有二级路由存到actionList,由于路由基本都是只到二级路由，因此不写递归
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          if (element.child.length > 0) {
            element.child.forEach((item) => {
              this.$set(this.actionList, item.id, []);
            });
          }
        }
      });
    },

    async getTreeData() {
      // 清空已经勾选的，如果没有这句，会显示上次勾选的，defaultChecked 用于显示哪些二级路由被勾选
      this.$refs.tree.setCheckedKeys([], false);

      for (const key in this.actionList) {
        if (Object.hasOwnProperty.call(this.actionList, key)) {
          this.actionList[key] = [];
        }
      }
      if (this.handelType == "create") {
        this.form = {
          description: "",
          name: "",
          permissionList: [],
        };
      } else {
        await api
          .commonGet({ apiName: "/role/detail", id: this.curId })
          .then((res) => {
            this.defaultChecked = [];

            this.form.permissionList = [];
            res.data.permissionList.forEach((element) => {
              this.defaultChecked.push(element.id);
              this.form.permissionList.push({ id: element.id, actions: "" });
              if (element.actions !== "") {
                this.actionList[element.id] = element.actions.split(",");
              }
            });

            this.form.id = res.data.id;
            this.form.name = res.data.name;
            this.form.description = res.data.description;
          });
      }

      this.formLoading = false;
    },

    async showEdit(obj) {
      this.formLoading = true;
      this.curId = obj.id;
      this.handelType = "update";
      this.dialogVisible = true;
    },

    async saveData() {
      this.form.permissionList.forEach((element) => {
        if (this.actionList[element.id]) {
          element.actions = this.actionList[element.id].join(",");
        }
      });

      try {
        let obj = Object.assign(
          {},
          { apiName: "/role/" + this.handelType },
          this.form
        );
        let res = await api.commonPost(obj);
        if (res.code == 200) {
          this.$message.success(res.message);
          this.dialogVisible = false;
          this.getDataList();
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },

    handleEdit() {
      api.update(this.form).then((res) => {
        if (res.code == 200) {
          this.$message({ message: "更新成功", type: "success" });
        } else {
          this.$message({ message: res.message, type: "error" });
        }
        this.getDataList();
        this.dialogVisible = false;
      });
    },
    showAdd() {
      this.handelType = "create";
      this.defaultChecked = [];
      this.dialogVisible = true;
      this.formLoading = true;
    },

    handleDel(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        let arr = [];
        arr.push(id);

        api.commonPost({apiName:'/role/remove', ids: arr }).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "删除成功", type: "success" });
          } else {
            this.$message({ message: res.message, type: "error" });
          }
          this.getDataList();
        });
      });
    },
    getActions(actions) {
      return actions.split(",");
    },
  },
};
</script>

<style lang="scss" scoped>
.actionFather {
  display: inline-block;
  min-width: 100px;
}
.actionList {
  display: inline-block;
}
.inlineForm .inlineFormSelect {
  width: 260px;
}

.inlineForm .inlineFormInput {
  width: 260px;
}

.el-form--inline .el-form-item {
  width: 48%;
}
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.info-form label {
  font-weight: normal;
}

.searchInput {
  width: 400px;
}
</style>
